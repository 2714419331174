"use client";

import * as Sentry from "@sentry/nextjs";
// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import Error from "next/error";
import { useEffect } from "react";

export default function GlobalError({ error }) {
    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        // biome-ignore lint/a11y/useHtmlLang: <explanation>
        <html>
            <body>
                <Error />
            </body>
        </html>
    );
}
